<template>
  <b-card title="التوظيفات">
    <b-tabs>
      <b-tab active>
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>اضافة توظيف</span>
        </template>
        <AddArticale />
      </b-tab>

      <b-tab @click="$router.push('/all-career')">
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span>التوظيفات</span>
        </template>
        <router-view />
        <!-- <AllArticales></AllArticales> -->
      </b-tab>

    </b-tabs>

  </b-card>
</template>

<script>

import {
  BTabs, BTab, BCard,
} from 'bootstrap-vue'

import AllArticales from './AllCareer.vue'
import AddArticale from './AddCareer.vue'

export default {
  components: {
    BCard,
    BTabs,
    BTab,

    AllArticales,
    AddArticale,
  },
  data() {
    return {

    }
  },
}
</script>
