<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        اضافة توظيف
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
    <validation-observer ref="addCourseFormvalidate">
      <b-form ref="form" class="mt-2" style="width: 100%" @submit.prevent="save">
        <b-row>
          <b-col md="4">
            <b-form-group label="العنوان" label-for="blog-edit-name" class="mb-2">
              <validation-provider #default="{ errors }" name="title" rules="required">
                <b-form-input id="blog-edit-name" v-model="addCourseForm.title" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="الفريق" label-for="blog-edit-name" class="mb-2">
              <validation-provider #default="{ errors }" name="team">
                <b-form-input id="blog-edit-name" v-model="addCourseForm.team" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="تاريخ الانتهاء" label-for="end_at" class="mb-2">
              <validation-provider #default="{ errors }" name="end_at">
                <flat-pickr v-model="addCourseForm.end_at" class="form-control" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="المستوى" label-for="blog-edit-name" class="mb-2">
              <validation-provider #default="{ errors }" name="level">
                <b-form-input id="blog-edit-name" v-model="addCourseForm.level" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="رقم التوظيف " label-for="blog-edit-name" class="mb-2">
              <validation-provider #default="{ errors }" name="position_code">
                <b-form-input id="blog-edit-name" v-model="addCourseForm.position_code" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="التوظيفات المرتبطة" label-for="blog-edit-category" class="mb-2">
              <validation-provider #default="{ errors }" name="Show In">
                <v-select
                  id="blog-edit-category"
                  v-model="addCourseForm.related_positions"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="PositionList"
                  multiple
                  :reduce="(val) => val.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="الموقع" label-for="blog-edit-slug" class="mb-2">
              <validation-provider #default="{ errors }" name="Sub name" rules="required">
                <b-form-input id="blog-edit-slug" v-model="addCourseForm.location" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="الحالة" label-for="blog-edit-slug" class="mb-2">
              <validation-provider #default="{ errors }" name="status" rules="required">
                <v-select
                  id="blog-edit-status"
                  v-model="addCourseForm.status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statusList"
                  :reduce="(val) => val.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="النوع" label-for="blog-edit-slug" class="mb-2">
              <validation-provider #default="{ errors }" name="type">
                <b-form-input id="blog-edit-slug" v-model="addCourseForm.type" />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="فوائد" label-for="blog-edit-slug" class="mb-2">
              <validation-provider #default="{ errors }" name="Sub name">
                <b-form-tags v-model="addCourseForm.benifits" />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Slug" label-for="blog-edit-cgory" class="mb-2">
              <validation-provider #default="{ errors }" name="Slug " rules="required">
                <b-form-input id="videor_link" v-model="addCourseForm.slug" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="الكلمات المفتاحية" label-for="blog-content" class="mb-2">
              <b-form-tags v-model="addCourseForm.keywords" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label=" العنوان الاضافي " label-for="blog-edit-title" class="mb-2">
              <validation-provider #default="{ errors }" name="Meta Title" rules="required">
                <b-form-input id="blog-edit-title" v-model="addCourseForm.meta_title" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="التوصيف الاضافي " label-for="blog-edit-slug" class="mb-2">
              <validation-provider #default="{ errors }" name="Meta Description" rules="required">
                <b-form-input id="blog-edit-slug" v-model="addCourseForm.meta_descprition" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="mb-2">
            <div class="border rounded p-2">
              <h4 class="mb-1">OG صورة</h4>
              <b-media no-body vertical-align="center" class="flex-column flex-md-row">
                <div class="d-inline-block">
                  <b-media-aside>
                    <b-img
                      ref="refPreviewE2"
                      :src="Tabimage"
                      height="110"
                      width="170"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    class="rounded ml-1 mt-2 text-center"
                    @click="
                      refPreviewE2.src = Tabimage;
                      addCourseForm.og_image = '';
                    "
                  >
                    ازالة الصورة
                  </b-button>
                </div>
                <b-media-body>
                  <b-card-text class="mt-1" />
                  <div class="d-inline-block mt-1">
                    <b-form-group label-for="blog-edit-name" class="mb-2">
                      <validation-provider #default="{ errors }" name="Tab Image " rules="">
                        <b-form-file
                          ref="refInputE2"
                          v-model="addCourseForm.og_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRendererTab"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
          </b-col>
          <b-col cols="12">
            <b-form-group label="الوصف" label-for="blog-content" class="mb-2">
              <validation-provider #default="{ errors }" name="description" rules="required">
                <QuillEditor v-model="addCourseForm.description" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="اللغات  " label-for="blog-content" class="mb-2">
              <validation-provider #default="{ errors }" name="skills_languages">
                <QuillEditor v-model="addCourseForm.skills_languages" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="ملاحظات" label-for="blog-content" class="mb-2">
              <validation-provider #default="{ errors }" name="Remarks">
                <QuillEditor v-model="addCourseForm.remarks" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="الكفاءات" label-for="blog-content" class="mb-2">
              <validation-provider #default="{ errors }" name="competencies">
                <QuillEditor v-model="addCourseForm.competencies" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="تفاصيل الوظيفة" label-for="blog-content" class="mb-2">
              <validation-provider #default="{ errors }" name="job_details" rules="required">
                <QuillEditor v-model="addCourseForm.job_details" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="المؤهلات" label-for="blog-content" class="mb-2">
              <validation-provider #default="{ errors }" name="qualification">
                <QuillEditor v-model="addCourseForm.qualification" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="الخبرة" label-for="blog-content" class="mb-2">
              <validation-provider #default="{ errors }" name="experience">
                <QuillEditor v-model="addCourseForm.experience" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="mt-50">
            <b-button
              v-if="!isLoading"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              حفظ
            </b-button>
            <b-button v-if="isLoading" variant="primary" disabled class="mr-1">
              جار الحفظ...
              <b-spinner small />
              <span class="sr-only" />
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'

import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, reactive } from '@vue/composition-api'
import Vue from 'vue'
import QuillEditor from "@core/components/editor/Editor";
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import store from '@/store'

export default {
  setup() {
    const refInputEl = ref(null)
    const refInputE2 = ref(null)
    const refPreviewEl = ref(null)
    const refPreviewE2 = ref(null)
    const getCourse = ref(false)
    const PositionList = ref([])
    const articleByCategoryList = ref([])
    const form = ref()
    const addCourseForm = reactive({
      title: '',
      description: '',
      remarks: '',
      competencies: '',
      skills_languages: '',
      position_code: '',
      location: '',
      type: '',
      meta_title: '',
      meta_descprition: '',
      benifits: '',
      keywords: '',
      related_positions: [],
      slug: '',
      status: [],
      team: '',
      level: ' ',
      end_at: '',
      og_image: '',
      experience: ' ',
      job_details: '',
      qualification: '',
    })
    const removeSpace = target => {
      addCourseForm.slug = target
        .toLowerCase()
        .replace(/\s+/g, '-')
        .replace(/&/g, '')
        .replace(/--/g, '-')
        .replace(/[.,]/g, '-')
        .replace(/[_]/g, '')
        .replace(/[!''""=]/g, '')
        .replace(/[!?%$^~*#()+]/g, '')
    }
    const Tabimage = ref('media/svg/files/blank-image.svg')
    const categoriesList = ref([])
    store.dispatch('career/allPosition').then(response => {
      PositionList.value = response.data
    })

    const isLoading = ref(false)
   
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        refPreviewEl.value.src = base64
      },
    )
    const statusList = ref([
      {
        id: 'passed',
        label: 'passed',
      },
      {
        id: 'draft',
        label: 'draft',
      },
      {
        id: 'published',
        label: 'published',
      },
    ])
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      base64 => {
        refPreviewE2.value.src = base64
      },
    )
    const addCourseFormvalidate = ref()

    const save = () => {
      const formData = new FormData()
      addCourseFormvalidate.value.validate().then(success => {
        if (success) {
          formData.append('title', addCourseForm.title)
          formData.append('description', addCourseForm.description)
          formData.append('remarks', addCourseForm.remarks)
          formData.append('competencies', addCourseForm.competencies)
          formData.append('qualification', addCourseForm.qualification)
          formData.append('experience', addCourseForm.experience)
          formData.append('job_details', addCourseForm.job_details)
          formData.append('skills_languages', addCourseForm.skills_languages)
          formData.append('position_code', addCourseForm.position_code)
          formData.append('benifits', addCourseForm.benifits)
          formData.append('keywords', addCourseForm.keywords)
          formData.append('slug', addCourseForm.slug)
          formData.append('related_positions', JSON.stringify(addCourseForm.related_positions))
          formData.append('location', addCourseForm.location)
          formData.append('type', addCourseForm.type)
          formData.append('meta_title', addCourseForm.meta_title)
          formData.append('meta_descprition', addCourseForm.meta_descprition)
          formData.append('status', addCourseForm.status)
          formData.append('team', addCourseForm.team)
          formData.append('level', addCourseForm.level)
          formData.append('end_at', addCourseForm.end_at)
          formData.append('og_image', addCourseForm.og_image)

          store.dispatch('career/addCareer', formData)
            .then(response => {
              Vue.swal({
                title: 'تم الاضافة  ',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
              form.value.reset()
              isLoading.value = false
              addCourseForm.related_positions = []
              addCourseForm.skills_languages = ''
              addCourseForm.qualification = ''
              addCourseForm.experience = ''
              addCourseForm.job_details = ''
              addCourseForm.competencies = ''
              addCourseForm.remarks = ''
              addCourseForm.position_code = ''
              addCourseForm.benifits = ''
              addCourseForm.keywords = ''
              addCourseForm.title = ''
              addCourseForm.team = ''
              addCourseForm.slug = ''
              addCourseForm.location = ''
              addCourseForm.type = ''
              addCourseForm.meta_title = ''
              addCourseForm.meta_descprition = ''
              addCourseForm.status = []
              addCourseForm.level = ''
              addCourseForm.status = ''
              addCourseForm.end_at = ''
              addCourseForm.og_image = ''
              refPreviewEl.value.src = 'media/svg/files/blank-image.svg'
              refPreviewE2.value.src = 'media/svg/files/blank-image.svg'

              form.value.reset()
            })
            .catch(error => {
              isLoading.value = false
              Vue.swal({
                name: '',
                text: `${error.response.data.message}`,
                icon: 'error',
                confirmButtonText: 'حسنا',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    }

    function example_image_upload_handler(blobInfo, success, failure, progress) {
      let xhr
      let formData
      const token = localStorage.getItem('token')
      xhr = new XMLHttpRequest()
      xhr.withCredentials = false
      xhr.open('POST', 'https://lpcentre.com/api/upload_image_tiny_mce')
      xhr.upload.onprogress = function (e) {
        progress((e.loaded / e.total) * 100)
      }
      xhr.setRequestHeader('Authorization', `Bearer ${token}`)
      xhr.onload = function () {
        let json
        if (xhr.status === 403) {
          failure(`HTTP Error: ${xhr.status}`, { remove: true })
          return
        }
        if (xhr.status < 200 || xhr.status >= 300) {
          failure(`HTTP Error: ${xhr.status}`)
          return
        }
        json = JSON.parse(xhr.responseText)
        if (!json || typeof json.location !== 'string') {
          failure(`Invalid JSON: ${xhr.responseText}`)
          return
        }
        success(json.location)
      }
      xhr.onerror = function () {
        failure(`Image upload failed due to a XHR Transport error. Code: ${xhr.status}`)
      }
      formData = new FormData()
      formData.append('image', blobInfo.blob(), blobInfo.filename())
      xhr.send(formData)
    }

    return {
      example_image_upload_handler,
      refInputEl,
      isLoading,
      refPreviewEl,
      refInputE2,
      refPreviewE2,
      inputImageRendererTab,
      getCourse,
      Tabimage,
      addCourseForm,
      inputImageRenderer,
      addCourseFormvalidate,
      articleByCategoryList,
      categoriesList,
      form,
      PositionList,
      save,
      removeSpace,
      required,
      email,
      statusList,
    }
  },
  components: {
    BCard,
    flatPickr,
    BMedia,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    QuillEditor,
    BMediaAside,
    BMediaBody,
    BForm,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
  },
  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
