var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"blog-edit-wrapper"},[_c('div',{staticClass:"text-center"},[_c('h4',{staticClass:"mt-5"},[_vm._v(" اضافة توظيف "),_c('feather-icon',{attrs:{"icon":"Edit2Icon"}})],1)]),_c('validation-observer',{ref:"addCourseFormvalidate"},[_c('b-form',{ref:"form",staticClass:"mt-2",staticStyle:{"width":"100%"},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"العنوان","label-for":"blog-edit-name"}},[_c('validation-provider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"blog-edit-name"},model:{value:(_vm.addCourseForm.title),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "title", $$v)},expression:"addCourseForm.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"الفريق","label-for":"blog-edit-name"}},[_c('validation-provider',{attrs:{"name":"team"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"blog-edit-name"},model:{value:(_vm.addCourseForm.team),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "team", $$v)},expression:"addCourseForm.team"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"تاريخ الانتهاء","label-for":"end_at"}},[_c('validation-provider',{attrs:{"name":"end_at"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",model:{value:(_vm.addCourseForm.end_at),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "end_at", $$v)},expression:"addCourseForm.end_at"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"المستوى","label-for":"blog-edit-name"}},[_c('validation-provider',{attrs:{"name":"level"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"blog-edit-name"},model:{value:(_vm.addCourseForm.level),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "level", $$v)},expression:"addCourseForm.level"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"رقم التوظيف ","label-for":"blog-edit-name"}},[_c('validation-provider',{attrs:{"name":"position_code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"blog-edit-name"},model:{value:(_vm.addCourseForm.position_code),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "position_code", $$v)},expression:"addCourseForm.position_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"التوظيفات المرتبطة","label-for":"blog-edit-category"}},[_c('validation-provider',{attrs:{"name":"Show In"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"blog-edit-category","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.PositionList,"multiple":"","reduce":function (val) { return val.id; }},model:{value:(_vm.addCourseForm.related_positions),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "related_positions", $$v)},expression:"addCourseForm.related_positions"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"الموقع","label-for":"blog-edit-slug"}},[_c('validation-provider',{attrs:{"name":"Sub name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"blog-edit-slug"},model:{value:(_vm.addCourseForm.location),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "location", $$v)},expression:"addCourseForm.location"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"الحالة","label-for":"blog-edit-slug"}},[_c('validation-provider',{attrs:{"name":"status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"blog-edit-status","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.statusList,"reduce":function (val) { return val.id; }},model:{value:(_vm.addCourseForm.status),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "status", $$v)},expression:"addCourseForm.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"النوع","label-for":"blog-edit-slug"}},[_c('validation-provider',{attrs:{"name":"type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"blog-edit-slug"},model:{value:(_vm.addCourseForm.type),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "type", $$v)},expression:"addCourseForm.type"}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"فوائد","label-for":"blog-edit-slug"}},[_c('validation-provider',{attrs:{"name":"Sub name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-tags',{model:{value:(_vm.addCourseForm.benifits),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "benifits", $$v)},expression:"addCourseForm.benifits"}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Slug","label-for":"blog-edit-cgory"}},[_c('validation-provider',{attrs:{"name":"Slug ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"videor_link"},model:{value:(_vm.addCourseForm.slug),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "slug", $$v)},expression:"addCourseForm.slug"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"الكلمات المفتاحية","label-for":"blog-content"}},[_c('b-form-tags',{model:{value:(_vm.addCourseForm.keywords),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "keywords", $$v)},expression:"addCourseForm.keywords"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":" العنوان الاضافي ","label-for":"blog-edit-title"}},[_c('validation-provider',{attrs:{"name":"Meta Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"blog-edit-title"},model:{value:(_vm.addCourseForm.meta_title),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "meta_title", $$v)},expression:"addCourseForm.meta_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"التوصيف الاضافي ","label-for":"blog-edit-slug"}},[_c('validation-provider',{attrs:{"name":"Meta Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"blog-edit-slug"},model:{value:(_vm.addCourseForm.meta_descprition),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "meta_descprition", $$v)},expression:"addCourseForm.meta_descprition"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"border rounded p-2"},[_c('h4',{staticClass:"mb-1"},[_vm._v("OG صورة")]),_c('b-media',{staticClass:"flex-column flex-md-row",attrs:{"no-body":"","vertical-align":"center"}},[_c('div',{staticClass:"d-inline-block"},[_c('b-media-aside',[_c('b-img',{ref:"refPreviewE2",staticClass:"rounded mr-2 mb-1 mb-md-0",attrs:{"src":_vm.Tabimage,"height":"110","width":"170"}})],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"rounded ml-1 mt-2 text-center",attrs:{"variant":"outline-secondary"},on:{"click":function($event){_vm.refPreviewE2.src = _vm.Tabimage;
                    _vm.addCourseForm.og_image = '';}}},[_vm._v(" ازالة الصورة ")])],1),_c('b-media-body',[_c('b-card-text',{staticClass:"mt-1"}),_c('div',{staticClass:"d-inline-block mt-1"},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label-for":"blog-edit-name"}},[_c('validation-provider',{attrs:{"name":"Tab Image ","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-file',{ref:"refInputE2",attrs:{"accept":".jpg, .png, .gif, .jpeg","placeholder":"Choose file"},on:{"input":_vm.inputImageRendererTab},model:{value:(_vm.addCourseForm.og_image),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "og_image", $$v)},expression:"addCourseForm.og_image"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)]),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"الوصف","label-for":"blog-content"}},[_c('validation-provider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('QuillEditor',{model:{value:(_vm.addCourseForm.description),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "description", $$v)},expression:"addCourseForm.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"اللغات  ","label-for":"blog-content"}},[_c('validation-provider',{attrs:{"name":"skills_languages"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('QuillEditor',{model:{value:(_vm.addCourseForm.skills_languages),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "skills_languages", $$v)},expression:"addCourseForm.skills_languages"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"ملاحظات","label-for":"blog-content"}},[_c('validation-provider',{attrs:{"name":"Remarks"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('QuillEditor',{model:{value:(_vm.addCourseForm.remarks),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "remarks", $$v)},expression:"addCourseForm.remarks"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"الكفاءات","label-for":"blog-content"}},[_c('validation-provider',{attrs:{"name":"competencies"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('QuillEditor',{model:{value:(_vm.addCourseForm.competencies),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "competencies", $$v)},expression:"addCourseForm.competencies"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"تفاصيل الوظيفة","label-for":"blog-content"}},[_c('validation-provider',{attrs:{"name":"job_details","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('QuillEditor',{model:{value:(_vm.addCourseForm.job_details),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "job_details", $$v)},expression:"addCourseForm.job_details"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"المؤهلات","label-for":"blog-content"}},[_c('validation-provider',{attrs:{"name":"qualification"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('QuillEditor',{model:{value:(_vm.addCourseForm.qualification),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "qualification", $$v)},expression:"addCourseForm.qualification"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"الخبرة","label-for":"blog-content"}},[_c('validation-provider',{attrs:{"name":"experience"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('QuillEditor',{model:{value:(_vm.addCourseForm.experience),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "experience", $$v)},expression:"addCourseForm.experience"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-50",attrs:{"cols":"12"}},[(!_vm.isLoading)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":_vm.save}},[_vm._v(" حفظ ")]):_vm._e(),(_vm.isLoading)?_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary","disabled":""}},[_vm._v(" جار الحفظ... "),_c('b-spinner',{attrs:{"small":""}}),_c('span',{staticClass:"sr-only"})],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }